export const personalData = {
  name: "Diwash Bhandari",
  headline: "Software Developer",
  img: "/images/profile.jpeg",
  city: "Kathmandu,Nepal",
  email: "diwasb54@gmail.com",
  description: `Software engineer with 4 years of experience in development and QA. Skilled in building scalable solutions, optimizing performance, and enhancing security. Proficient in Python, JavaScript, Go, and PHP, with expertise in RESTful APIs, advanced testing, and cross-functional collaboration. Focused on delivering high-quality, reliable applications.`,
  tools: [
    {
      id: 1,
      title: "Git",
      image: "/assets/tools/git.png",
    },
    {
      id: 2,
      title: "Visual Studio Code",
      image: "/assets/tools/vscode.png",
    },
    {
      id: 3,
      title: "Nginx Server",
      image: "/assets/tools/nginx.png",
    },
    {
      id: 4,
      title: "Postman",
      image: "/assets/tools/postman.png",
    },
    {
      id: 5,
      title: "Docker",
      image: "/assets/tools/docker.png",
    },
    {
      id: 5,
      title: "Jira",
      image: "/assets/tools/jira.png",
    },
    {
      id: 6,
      title: "Figma",
      image: "/assets/tools/figma.png",
    },
  ],
};

export const social_medias = {
  socialists: [
    {
      id: "1",
      title: "Facebook",
      name: "facebook",
      link: "https://www.facebook.com/diwasb55/",
      icon: "bx bxl-facebook",
    },
    {
      id: "2",
      title: "Medium",
      name: "medium",
      link: "https://medium.com/@diwasb54",
      icon: "bx bxl-medium",
    },
    {
      id: "3",
      title: "Github",
      name: "github",
      link: "https://github.com/Diwas2055/",
      icon: "bx bxl-github",
    },
    {
      id: "5",
      title: "Google",
      name: "google",
      link: "mailto:diwasb54@gmail.com",
      icon: "bx bxl-google",
    },
    {
      id: "4",
      title: "Linkedin",
      name: "linkedin",
      link: "https://www.linkedin.com/in/diwash-bhandari-3814b2157/",
      icon: "bx bxl-linkedin",
    },
  ],
};

export const CV = {
  name: "Resume",
  link: "https://meroweb.notion.site/Diwash-Bhandari-c2fc715b0a214bb1ae00f714429454e0",
  // link: "/resume.pdf",
  icon: "icofont-download text-white",
};

// Function to generate a random background color class with additional classes
function getRandomBgColorClass() {
  const colors = [
    "bg-success",
    "bg-warning",
    "bg-danger",
    "bg-info",
    "bg-primary",
    "bg-secondary",
    "bg-dark",
    "bg-primary",
    "bg-info",
  ]; // Add more colors as needed
  const randomIndex = Math.floor(Math.random() * colors.length);
  const randomColorClass = colors[randomIndex];

  // Additional classes
  const additionalClasses =
    "progress-bar progress-bar-striped progress-bar-animated";

  return `${randomColorClass} ${additionalClasses}`;
}
// Your SkillsList array with random background colors and additional classes
// export const SkillsList = [
//   {
//     name: "Python/FastAPI Framework",
//     skill: "70",
//     style: "70%",
//     class: getRandomBgColorClass(),
//   },
//   {
//     name: "Javascript/React.Js",
//     skill: "60",
//     style: "60%",
//     class: getRandomBgColorClass(),
//   },
//   {
//     name: "PHP/Laravel Framework",
//     skill: "80",
//     style: "80%",
//     class: getRandomBgColorClass(),
//   },
//   {
//     name: "Edgedb/PostgreSQL/MySQL",
//     skill: "80",
//     style: "80%",
//     class: getRandomBgColorClass(),
//   },
//   {
//     name: "DevOps/Git",
//     skill: "70",
//     style: "70%",
//     class: getRandomBgColorClass(),
//   },
//   {
//     name: "Docker",
//     skill: "60",
//     style: "60%",
//     class: getRandomBgColorClass(),
//   },
// ];

export const SkillsList = [
  {
    name: "Python",
    skill: "70",
    style: "70%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/python.svg",
  },
  {
    name: "PHP",
    skill: "80",
    style: "80%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/php.svg",
  },
  {
    name: "Javascript",
    skill: "60",
    style: "60%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/javascript.svg",
  },
  {
    name: "PostgreSQL",
    skill: "80",
    style: "80%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/postgresql.svg",
  },
  {
    name: "Edgedb",
    skill: "80",
    style: "80%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/edgedb.svg",
  },
  {
    name: "MySQL",
    skill: "80",
    style: "80%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/mysql.svg",
  },
  {
    name: "FastAPI Framework",
    skill: "70",
    style: "70%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/fastapi.svg",
  },
  {
    name: "Laravel Framework",
    skill: "80",
    style: "80%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/laravel.svg",
  },
  {
    name: "React.Js",
    skill: "60",
    style: "60%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/react.svg",
  },
  {
    name: "Nginx Server",
    skill: "70",
    style: "70%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/nginx.svg",
  },
  {
    name: "Git",
    skill: "70",
    style: "70%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/git.svg",
  },
  {
    name: "Docker",
    skill: "60",
    style: "60%",
    class: getRandomBgColorClass(),
    imageLink: "/assets/skills/docker.svg",
  },
];


export const EducationList = [
  {
    id: "1",
    name: "Nepal Commerce Campus (NCC)",
    location: "Min Bhavan , Kathamndu",
    level: "Bachelor In Information Management(BIM)",
    start_date: "2073",
    end_date: "2078",
  },
  {
    id: "2",
    name: "Ambition College",
    location: "Mid Baneshwor , Kathamndu",
    level: "Hseb",
    start_date: "2071",
    end_date: "2073",
  },
  {
    id: "3",
    name: "Newton Education Academy",
    location: "Birtamode, Jhapa",
    level: "Slc",
    start_date: "",
    end_date: "2070",
  },
];
export const ExperienceList = [
  {
    id: "1",
    level: "Software Engineer",
    join_date: "June-2023",
    end_date: "Present",
    office: "Codavatar Tech Pvt. Ltd.",
    location: "Behind BICC, Bajracharya House Kathmandu, Nepal",
    lists: [
      {
        id: 1,
        description:
          "Engineered and optimized VoIP solutions, including IVR and call recording, significantly enhancing system efficiency.",
      },
      {
        id: 2,
        description:
          "Led CRM integrations with HubSpot and Pipedrive, ensuring smooth data synchronization and improved customer relationship management.",
      },
      {
        id: 3,
        description:
          "Developed a custom framework blueprint on top of FastAPI, streamlining application development and boosting system scalability.",
      },
      {
        id: 4,
        description:
          "Mastered modern technologies such as EdgeDB, gRPC, GraphQL, and functional programming to deliver high-performance, scalable solutions.",
      },
    ]
  },
  {
    id: "2",
    level: "Software Engineer",
    join_date: "May-2022",
    end_date: "July-2023",
    office: "Chuchuro Firm",
    location: "Sinamangal Kathmandu",
    lists: [
      {
        id: 1,
        description:
          "Developing and maintaining Python-based applications using Peewee ORM, Tornado Framework, RabbitMQ, Meilisearch, and other technologies.",
      },
      {
        id: 2,
        description:
          "Writing clean, maintainable, and testable code in Python, using Pytest for testing. Working as QA testing both ( manual testing, and automatic testing).",
      },
      {
        id: 3,
        description:
          "Implementing message queues with RabbitMQ for asynchronous processing. Integrating search capabilities into applications using Meilisearch.",
      },
    ],
  },
  {
    id: "3",
    level: "Software Engineer",
    join_date: "Dec-2020",
    end_date: "April-2022",
    office: "Young Minds Creation (P) Ltd",
    location: "Young Minds Tower",
    lists: [
      {
        id: 1,
        description: `Developing and maintaining complex Laravel-based web applications.`,
      },
      {
        id: 2,
        description: `Writing clean, maintainable, and testable PHP code, utilizing Laravel's built-in features.`,
      },
      {
        id: 3,
        description: `Building and integrating RESTful APIs for data exchange between multiple systems. Implementing security measures, such as password hashing and encryption, to ensure data protection.`,
      },
      {
        id: 4,
        description: `Integrating third-party packages and libraries to extend the functionality of the application.`,
      },
    ],
  },
  {
    id: "4",
    level: "Graphic Designer",
    join_date: "May-2019",
    end_date: "Jan-2020",
    office: "Pinches Artcore",
    location: "",
    lists: [
      {
        id: 1,
        description: `Creating designs for various mediums, such as print materials, digital platforms, and social media.`,
      },
      {
        id: 2,
        description: `Designing logos, brochures, flyers, posters, and other marketing materials.`,
      },
      {
        id: 3,
        description: `Working with clients to understand their design needs and preferences.`,
      },
    ],
  },
];
